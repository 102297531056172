import React from "react"
import Layout from "../../components/h/layout"

const HalloweenJPage = () => (
  <Layout>
    <h2>The last stop on this scavenger hunt will keep you cool</h2>
    <h2>Ice cream and desserts will make you drool.</h2>
  </Layout>
)

export default HalloweenJPage
